import React from "react"
import { Box, Flex, Grid, Heading, Text } from "@theme-ui/components"
import { getReferencePath } from "../../utils/path"
import { GatsbyImage } from "gatsby-plugin-image"
import { InboundLink, OutboundLink } from "../link"
import RichContentStructuredText from "../richContentStructuredText"
import ArrowRight from "../../assets/images/arrow-right.svg"

const ProductReferenceThumb = ({ reference, small }) => {
  console.log(reference)
  return (
    <Flex
      sx={{
        py: [0, 0, 7],
        flexDirection: [
          "column-reverse",
          "column-reverse",
          "column-reverse",
          "row",
        ],
        position: "relative",
      }}
    >
      <Flex
        sx={{
          width: ["100%", "50%"],
          flexDirection: "column",
          py: [0],
          pr: [0, 7],
        }}
      >
        <Box>
          <Heading as="div" variant="sectionTitle">
            <Box
              as="h2"
              variant="h2"
              sx={{
                p: {
                  fontSize: "32px",
                  fontWeight: 300,
                  letterSpacing: "0",
                },
              }}
            >
              <RichContentStructuredText text={reference.body} />
            </Box>
          </Heading>
        </Box>

        <Box
          sx={{
            "&:hover": {
              div: {
                color: "black!important",
              },
              svg: {
                circle: {
                  stroke: "dark",
                  strokeWidth: "2px",
                },
                use: {
                  fill: "dark",
                },
                "g#Group": {
                  fill: "dark",
                },
              },
            },
            a: {
              display: "inline-block",
              position: "relative",
            },
          }}
        >
          <OutboundLink
            to={reference.link.url}
            sx={{
              textDecoration: "none",
              position: "relative",
            }}
          >
            <Box
              sx={{
                border: "1px solid light!important",
                borderColor: "light!important",
                color: "light",
                backgroundColor: "primary",
                py: [3],
                px: [4],
                mb: [3, 4],
                width: "auto",
                display: "inline-flex",
                transition: "all .3s ease-out",
              }}
            >
              Scarica il catalogo
              <Flex
                variant="svg.arrow.light"
                sx={{
                  alignItems: "center",
                  alignContent: "center",
                  width: "24px",
                  height: "24px",
                  ml: [3],
                }}
              >
                <ArrowRight />
              </Flex>
            </Box>
          </OutboundLink>
        </Box>
      </Flex>
      <Box
        sx={{
          position: ["relative"],
          width: ["100%", "50%"],
        }}
      >
        <Box
          sx={{
            position: ["relative"],
            width: ["100%"],
            maxHeight: ["450px"],
            height: ["450px"],
            ".gatsby-image-wrapper": {
              width: "100%",
              height: "100%",
            },
          }}
        >
          {reference.image.gatsbyImageData && (
            <GatsbyImage image={reference.image.gatsbyImageData} alt="" />
          )}
        </Box>
      </Box>
    </Flex>
  )
}

export default ProductReferenceThumb
