import React from "react"
import { getCategoryFamilyPath, getCategoryPath } from "../utils/path"
import { Grid, Box, Flex } from "theme-ui"
import CategoryThumb from "./categoryThumb"
import ProductThumb from "./productThumb"
import { InboundLink, OutboundLink } from "./link"
import loadable from "@loadable/component"
import { MagicLink } from "../utils/magicLink"
import ArrowRight from "../assets/images/arrow-right.svg"
import FeaturedProduct from "./blocks/featuredProduct"
import ProductReferenceThumb from "./blocks/productReferenceThumb"

const DocumentPreview = loadable(() => import("./blocks/documentPreview"), {
  ssr: false,
})

const PageGridList = ({
  type,
  categories,
  familyCategories,
  productCategories,
  parentCategory,
  productCatalog,
  page,
}) => {
  const checkParentCategory = (parentCategories, category) => {
    return JSON.parse(JSON.stringify(parentCategories)).nodes.filter(
      familyCategory => {
        let foundParent = familyCategory.categories.filter(categories => {
          return (
            JSON.parse(JSON.stringify(categories)).id ===
            JSON.parse(JSON.stringify(category)).id
          )
        })
        if (foundParent.length > 0) return familyCategory
      }
    )[0]
  }
  const products = page.products
    ? page.products.filter(product => product.availability === "InStock")
    : null

  const foundCategoryParent = checkParentCategory(familyCategories, page)
  return (
    <Box>
      <Grid columns={[1, 1, 1, ".2fr .8fr"]} gap={[2, 2, 2, 6]}>
        <Box sx={{ display: ["none", "none", "none", "block"] }}>
          <Box>
            <List>
              {familyCategories.nodes.length > 0 &&
                familyCategories.nodes.map(category => (
                  <Item sx={{ my: [2] }}>
                    <InboundLink
                      variant="links.normalLink"
                      state={{ category: category.title }}
                      sx={{
                        color: "dark",
                        fontSize: [2],
                        color:
                          type === "family" && page.id === category.id
                            ? "primary"
                            : "dark",
                        fontWeight:
                          type === "family" && category.id === page.id
                            ? "bold"
                            : type === "family" && page.id === category.id
                            ? "primary"
                            : type === "category" &&
                              category.title == foundCategoryParent.title
                            ? "bold"
                            : "normal",
                      }}
                      to={getCategoryFamilyPath(category)}
                    >
                      {category.shortTitle || category.title}
                    </InboundLink>
                    {type === "category" &&
                      category.title == foundCategoryParent.title && (
                        <Box sx={{ pl: [3], my: [2] }}>
                          <List>
                            {categories.nodes.map(category => {
                              if (
                                checkParentCategory(parentCategory, category) &&
                                checkParentCategory(parentCategory, category)
                                  .title === foundCategoryParent.title
                              )
                                return (
                                  <Item>
                                    <InboundLink
                                      className="sidebar-product-link"
                                      variant="links.normalLink"
                                      sx={{
                                        color:
                                          category.title === page.title
                                            ? "primary"
                                            : "dark",
                                        fontWeight:
                                          category.title === page.title
                                            ? "bold"
                                            : "normal",
                                        fontSize: [2],
                                      }}
                                      to={getCategoryPath(
                                        category,
                                        page.locale,
                                        familyCategories
                                      )}
                                    >
                                      {category.shortTitle
                                        ? category.shortTitle
                                        : category.title}
                                    </InboundLink>
                                  </Item>
                                )
                              return <span></span>
                            })}
                          </List>
                        </Box>
                      )}
                  </Item>
                ))}
            </List>
          </Box>
        </Box>
        <Box>
          <Box>
            <Box
              sx={{ p: { fontSize: [3, 5], mt: [0], fontWeight: "300" } }}
              dangerouslySetInnerHTML={{ __html: page.subsubtitle }}
            />
          </Box>
          {page.content && (
            <>
              {page.content.map(block => (
                <Box sx={{ mb: [3, 4] }}>
                  {block.model.apiKey === "featured_product" && (
                    <FeaturedProduct
                      kicker={block.kicker}
                      title={block.title}
                      subtitle={block.subtitle}
                      product={block.product}
                      familyCategories={familyCategories}
                      productCategories={productCategories}
                    />
                  )}
                </Box>
              ))}
            </>
          )}
          <Grid
            columns={[1, type === "family" ? 2 : products.length > 0 ? 2 : 1]}
            gap={[2, 2, 2, 4]}
          >
            {type === "family" &&
              page.categories.map(category => {
                return (
                  <Box sx={{ maxHeight: ["400px"] }}>
                    <CategoryThumb
                      category={category}
                      parentCategory={familyCategories}
                    />
                  </Box>
                )
              })}

            {type === "category" &&
              products.length > 0 &&
              products.map(product => {
                return (
                  <Box sx={{ maxHeight: ["350px"] }}>
                    <ProductThumb
                      product={product}
                      parentFamily={familyCategories}
                      parentCategory={categories}
                    />
                  </Box>
                )
              })}
            {/* {type === "category" &&
              page.catalogue &&
              page.catalogue.documents.length > 0 && (
                <Grid columns={[1, 1, "2fr 1fr"]}>
                  <Box
                    sx={{
                      maxHeight: "500px",
                      overflow: "auto",
                      minHeight: ["300px", "500px", "700px"],
                      border: "1px solid",
                      borderColor: "#eeeeee",
                    }}
                  >
                    <DocumentPreview document={page.catalogue.documents[0]} />
                  </Box>
                  <Box sx={{ pb: [4] }}>
                    <MagicLink
                      target="_blank"
                      variant="buttons.primary"
                      href={page.catalogue.documents[0].url}
                      sx={{
                        position: "relative",
                        display: "inline-block",
                        width: "auto",
                      }}
                    >
                      <Flex
                        sx={{
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box>Scarica il catalogo</Box>
                        <Flex
                          variant="svg.arrow.light"
                          sx={{
                            alignItems: "center",
                            alignContent: "center",
                            width: "24px",
                            height: "24px",
                            ml: [4],
                          }}
                        >
                          <ArrowRight />
                        </Flex>
                      </Flex>
                    </MagicLink>
                  </Box>
                </Grid>
              )} */}
          </Grid>
          {productCatalog && (
            <ProductReferenceThumb reference={productCatalog} />
          )}
        </Box>
      </Grid>
    </Box>
  )
}

const List = props => {
  return (
    <Flex
      {...props}
      sx={{
        flexDirection: "column",
        margin: 0,
        padding: 0,
        mb: 0,
        listStyle: "none",
        a: {
          textDecoration: "none",
          "&:hover": {
            textDecoration: "underline",
          },
        },
      }}
      as="ul"
    />
  )
}

const Item = props => {
  return (
    <Box
      {...props}
      as="li"
      sx={{ mb: 0, py: [1], "&:last-child": { mb: 0 } }}
    />
  )
}

export default PageGridList
