import React from "react"
import { graphql } from "gatsby"
import { Box, Container, Flex } from "@theme-ui/components"
import Layout from "../components/layout"
import { getCategoryFamilyPath } from "../utils/path"
import { HelmetDatoCms } from "gatsby-source-datocms"
import PageHero from "./pageHero"
import { useFavicon } from "../hooks/useFavicon"
import PageArticleThumb from "../components/blocks/pageArticleThumb"
import FooterServiceInformation from "../components/blocks/footerServiceInformation"
import PageSubtitle from "../components/pageSubtitle"
import PageGridList from "../components/pageGridList"
import RichContentStructuredText from "../components/richContentStructuredText"
import FilterMetaTagDescription from "../utils/filterMetaTagDescription"
import FeaturedProduct from "../components/blocks/featuredProduct"

const Page = ({
  data: {
    page,
    familyCategories,
    productCategories,
    site,
    articles,
    footerServiceInformation,
  },
}) => {
  const favicon = useFavicon().site.faviconMetaTags
  const pageAllSlugLocales = page._allSlugLocales.sort(function (a, b) {
    return site.locales.indexOf(a.locale) - site.locales.indexOf(b.locale)
  })

  const i18nPaths = pageAllSlugLocales.map(locale => {
    return {
      locale: locale.locale,
      value: getCategoryFamilyPath(page, locale.locale),
    }
  })

  return (
    <Layout locale={page.locale} i18nPaths={i18nPaths}>
      <HelmetDatoCms
        seo={FilterMetaTagDescription(page.seoMetaTags)}
        favicon={favicon}
      >
        <html lang={page.locale} />
      </HelmetDatoCms>
      <PageHero page={page} image={page.heroImage} />
      <PageSubtitle subtitle={page.subtitle} />
      <Box>
        <Container
          sx={{
            position: "relative",
            mt: [0],
          }}
        >
          <PageGridList
            type="family"
            familyCategories={familyCategories}
            productCategories={productCategories}
            page={page}
          />
        </Container>
      </Box>
      <Box>
        <Container>
          <RichContentStructuredText text={page.body} />
        </Container>
      </Box>
      <Box>
        {page.newsarticle && <PageArticleThumb article={page.newsarticle} />}
      </Box>
      <Box>
        <FooterServiceInformation services={footerServiceInformation.service} />
      </Box>
    </Layout>
  )
}

export default Page

export const query = graphql`
  query ProductFamilyQuery($id: String!, $locale: String!) {
    site: datoCmsSite {
      locales
    }
    page: datoCmsProductFamily(id: { eq: $id }) {
      id
      locale
      title
      shortTitle
      subtitle
      subsubtitle
      slug
      body {
        value
        blocks {
          __typename
          ... on DatoCmsContactForm {
            id: originalId
            kicker
            subtitle
            title
            newsletterDescription
            model {
              apiKey
            }
          }
          ... on DatoCmsSimpleImageAndText {
            id: originalId
            title
            image {
              gatsbyImageData(
                width: 1480
                placeholder: BLURRED
                forceBlurhash: false
              )
            }
            model {
              apiKey
            }
          }
          ... on DatoCmsTextBlock {
            id: originalId
            title
            darkBackground
            body {
              value
            }
            alignLeftOrTop
            model {
              apiKey
            }
          }
        }
      }

      content {
        ... on DatoCmsFeaturedProduct {
          kicker
          title
          subtitle
          product {
            ...ProductPageDetails
          }
          model {
            apiKey
          }
        }
      }

      categories {
        ...ProductCategoryPageDetails
        model {
          apiKey
        }
      }
      newsarticle {
        id
        title
        slug
        locale
        body {
          value
        }
        _allSlugLocales {
          locale
          value
        }
        heroImage {
          gatsbyImageData(
            width: 1480
            placeholder: BLURRED
            forceBlurhash: false
            imgixParams: {
              blendColor: "#212C30"
              blendMode: "multiply"
              blendAlpha: 60
            }
          )
        }
        meta {
          firstPublishedAt(locale: $locale, formatString: "DD MMMM Y")
        }
        model {
          apiKey
        }
      }
      model {
        apiKey
      }
      ...AllProductFamilySlugLocales
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      image {
        gatsbyImageData(
          width: 1920
          placeholder: NONE
          forceBlurhash: false
          imgixParams: {
            blendColor: "#212C30"
            blendMode: "multiply"
            blendAlpha: 45
          }
        )
      }
      heroImage {
        url
        gatsbyImageData(
          width: 1920
          placeholder: BLURRED
          forceBlurhash: false
          imgixParams: {
            blendColor: "#212C30"
            blendMode: "multiply"
            blendAlpha: 60
          }
        )
      }
      model {
        apiKey
      }
    }

    productCategories: allDatoCmsProductCategory(
      filter: { slug: { ne: null }, locale: { eq: $locale } }
      sort: { fields: position, order: ASC }
    ) {
      nodes {
        id
        slug
        title
        locale
        products {
          id
          title
          slug
          locale
        }
      }
    }

    familyCategories: allDatoCmsProductFamily(
      filter: { slug: { ne: null }, locale: { eq: $locale } }
      sort: { fields: position, order: ASC }
    ) {
      nodes {
        id
        title
        shortTitle
        locale
        slug
        categories {
          id
          title
          slug
        }
        model {
          apiKey
        }
      }
    }

    footerServiceInformation: datoCmsServiceInformation {
      id
      service {
        ... on DatoCmsRichContent {
          ...RichContent
        }
      }
    }
  }

  fragment ProductFamilyDetails on DatoCmsProductFamily {
    id
    title
    shortTitle
    slug
    thumbnailImage {
      gatsbyImageData(width: 800, placeholder: NONE, forceBlurhash: false)
    }
    locale
    model {
      apiKey
    }
  }

  fragment ProductCategoryPageDetails on DatoCmsProductCategory {
    ecommerce
    id
    locale
    title
    shortTitle
    subtitle
    slug
    shortDescription
    products {
      id
      title
      slug
    }
    model {
      apiKey
    }

    thumbnailImage {
      gatsbyImageData(width: 1920, placeholder: NONE, forceBlurhash: false)
    }
    image {
      gatsbyImageData(
        width: 1920
        placeholder: NONE
        forceBlurhash: false
        imgixParams: {
          blendColor: "#212C30"
          blendMode: "multiply"
          blendAlpha: 30
        }
      )
    }
    heroImage {
      gatsbyImageData(
        width: 1920
        placeholder: NONE
        forceBlurhash: false
        imgixParams: {
          blendColor: "#212C30"
          blendMode: "multiply"
          blendAlpha: 60
        }
      )
    }
  }

  fragment AllProductFamilySlugLocales on DatoCmsProductFamily {
    _allSlugLocales {
      value
      locale
    }
  }
`
