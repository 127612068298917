import React from "react"
import {
  Box,
  Flex,
  Grid,
  Heading,
  Text,
  AspectRatio,
} from "@theme-ui/components"
import { getCategoryPath } from "../utils/path"
import { GatsbyImage } from "gatsby-plugin-image"
import { i18nContext } from "../context/i18nContext"
import { Plus } from "react-feather"
import { getColor } from "@theme-ui/color"
import themeUiTheme from "../gatsby-plugin-theme-ui"
import { InboundLink, OutboundLink } from "./link"
import ArrowRight from "../assets/images/arrow-right.svg"

const CategoryThumb = ({ category, parentCategory }) => {
  const primary = getColor(themeUiTheme, "primary")
  const dark = getColor(themeUiTheme, "dark")
  console.log(category)
  return (
    <Box>
      <InboundLink
        className="category-thumb-link"
        sx={{
          color: "dark",
          position: "relative",
          display: "block",
          width: "100%",
          height: "100%",
          "> div": {
            height: "100%",
            maxHeight: "350px",
          },
        }}
        state={{ category }}
        to={getCategoryPath(category, category.locale, parentCategory)}
      >
        <AspectRatio ratio={1}>
          <Box
            sx={{
              overflow: "hidden",
              backgroundColor: "dark",
              width: "100%",
              height: "100%",
              position: "relative",
              ".gatsby-image-wrapper": {
                position: "absolute",
                width: ["65%"],
                height: ["65%"],
                right: [2],
                bottom: [3],
                overflow: "initial",
                img: {
                  right: [2],
                  left: "unset",
                  width: "auto",
                  height: "100%",
                  objectFit: "contain!important",
                },
              },
              "&:hover .overlay": {
                width: "120px",
                height: "120px",
                transform: "scale(12,12) translateZ(0)",
              },
            }}
          >
            <Box
              className="overlay"
              sx={{
                position: "absolute",
                width: ["0px"],
                height: ["0px"],
                borderRadius: "50%",
                backgroundColor: "primary",
                right: [0],
                bottom: [0],
                zIndex: "0",
                transition: "transform 0.4s ease-out, width .6s, height .6s",
              }}
            ></Box>
            {category.thumbnailImage && (
              <GatsbyImage
                image={category.thumbnailImage.gatsbyImageData}
                alt=""
              />
            )}
            <Flex
              sx={{
                position: "absolute",
                top: 0,
                p: [3],
                height: "100%",
                justifyContent: "space-between",
                alignContent: "center",
                flexDirection: "column",
              }}
            >
              <Box>
                <Box sx={{ width: "100%", color: "light" }}>
                  <Heading
                    as="h2"
                    variant="h4"
                    sx={{ mt: 0, mb: [3], color: "light" }}
                  >
                    {category.shortTitle ? category.shortTitle : category.title}
                  </Heading>
                </Box>
                <Box
                  as="p"
                  sx={{ fontSize: 1, color: "light", width: "70%" }}
                  dangerouslySetInnerHTML={{
                    __html: category.shortDescription,
                  }}
                />
              </Box>
              <Flex sx={{ textAlign: "left", color: "light" }}>
                <Box>Vedi tutte</Box>
                <Flex
                  variant="svg.arrow.light"
                  sx={{
                    alignItems: "center",
                    alignContent: "center",
                    width: "24px",
                    height: "24px",
                    ml: [2, 4],
                  }}
                >
                  <ArrowRight />
                </Flex>
              </Flex>
            </Flex>
          </Box>
        </AspectRatio>
      </InboundLink>
      {category.ecommerce && (
        <OutboundLink
          sx={{
            color: "primary",
            position: "relative",
            display: "block",
            width: "100%",
            height: "100%",
            textDecoration: "none",
          }}
          state={{ category }}
          to="https://shop.socaf.it/"
        >
          <Flex
            sx={{
              backgroundColor: "dark",
              p: 3,

              ":hover": {
                backgroundColor: "primary",
                color: "light",
                svg: {
                  circle: {
                    stroke: "white",
                    strokeWidth: "2px",
                  },
                  use: {
                    fill: "white",
                  },
                  "g#Group": {
                    fill: "white",
                  },
                },
              },

              color: "primary",
            }}
          >
            <Box>Acquista subito</Box>
            <Flex
              variant="svg.arrow.primary"
              sx={{
                alignItems: "center",
                alignContent: "center",
                width: "24px",
                height: "24px",
                ml: [2, 4],
              }}
            >
              <ArrowRight />
            </Flex>
          </Flex>
        </OutboundLink>
      )}
    </Box>
  )
}

export default CategoryThumb
