import React from "react"
import { Box, Flex, Grid, Heading, Text, Container } from "@theme-ui/components"
import { getArticlePath, getNewsPath } from "../../utils/path"
import { GatsbyImage } from "gatsby-plugin-image"
import { InboundLink, OutboundLink } from "../link"
import NewsSectionArticleCard from "../newsSectionArticleCard"
import ArrowRight from "../../assets/images/arrow-right.svg"
import { render } from "datocms-structured-text-to-plain-text"

const PageArticleThumb = ({ article, small }) => {

  return (
    <Box>
      <Container>
        <Text as="div" variant="sectionTitle">
          <Flex as="div" sx={{ justifyContent: "space-between" }}>
            <Box>
              <span>News</span>
            </Box>
            <Box>
              <Box sx={{ display: "inline-block" }}>
                <InboundLink
                  sx={{ display: "block" }}
                  variant="buttons.primaryEmpty"
                  to={getNewsPath(article.locale)}
                >
                  <Flex sx={{ alignItems: "center", alignContent: "center" }}>
                    <Flex
                      sx={{ alignItems: "center", alignContent: "center" }}
                      mr={[3]}
                    >
                      Leggi tutte le news
                    </Flex>
                    <Flex
                      variant="svg.arrow.red"
                      sx={{
                        alignItems: "center",
                        alignContent: "center",
                        width: "24px",
                        height: "24px",
                      }}
                    >
                      <ArrowRight />
                    </Flex>
                  </Flex>
                </InboundLink>
              </Box>
            </Box>
          </Flex>
        </Text>
        <Grid columns={[1, ".3fr .7fr"]} sx={{ my: [4, 6] }}>
          <Box
            sx={{
              position: "relative",
              top: 0,
              left: 0,
              height: ["100%"],
              width: "100%",
              ".gatsby-image-wrapper": {
                height: ["100%"],
                width: "100%",
              },
              overflow: "hidden",
            }}
          >
            {article.heroImage.gatsbyImageData && (
              <GatsbyImage image={article.heroImage.gatsbyImageData} />
            )}
          </Box>
          <Flex
            sx={{
              p: [4],
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
            }}
          >
            <Box>
              <Heading as="h5" variant="h5"  sx={{mt:0}}>
                {article.title}
              </Heading>
            </Box>
            <Box>
              <Text
                as="p"
                sx={{ fontSize: 1, color:  "dark"  }}
              >
                {render(article.body)
                  ? render(article.body).substring(0, 320) + " ..."
                  : ""}
              </Text>
            </Box>
            <Box>
              <Box sx={{ display: "inline-block" }}>
                <InboundLink
                  sx={{ display: "block", pl: [0] }}
                  variant="buttons.primaryEmpty"
                  to={getArticlePath(article, article.locale)}
                >
                  <Flex sx={{ alignItems: "center", alignContent: "center" }}>
                    <Flex
                      sx={{ alignItems: "center", alignContent: "center" }}
                      mr={[3]}
                    >
                      Leggi di più
                    </Flex>
                    <Flex
                      variant="svg.arrow.red"
                      sx={{
                        alignItems: "center",
                        alignContent: "center",
                        width: "24px",
                        height: "24px",
                      }}
                    >
                      <ArrowRight />
                    </Flex>
                  </Flex>
                </InboundLink>
              </Box>
            </Box>
            <Flex
              sx={{
                justifyContent: "space-between",
                alignItems: "start",
                pt: [4],
                borderTop:"1px solid",
                borderColor: "dark",
                mt:[4]

              }}
            >
              <Box>
                <Heading as="p" sx={{ fontSize: [9], color: "dark", mt:[0] }}>
                  {article.meta.firstPublishedAt.split(/(\s+)/)[0]}
                </Heading>
              </Box>
              <Box sx={{ textTransform: "capitalize", textAlign: "right" }}>
                <Box>
                  {
                    article.meta.firstPublishedAt
                      .split(/(\s+)/)
                      .filter(e => e.trim().length > 0)[1]
                  }
                </Box>
                <Box>
                  {
                    article.meta.firstPublishedAt
                      .split(/(\s+)/)
                      .filter(e => e.trim().length > 0)[2]
                  }
                </Box>
              </Box>
            </Flex>
          </Flex>
        </Grid>
      </Container>
    </Box>
  )
}

export default PageArticleThumb
